export const fetchRequestForm = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(
      "https://backend-v2-dot-choizy-prod.nw.r.appspot.com/api/schools/contact-us",
      requestOptions
    );
    const data = await response.json();
    return data.success;
  } catch (error) {
    console.log("error.message :>> ", error.message);
  }
};
