import { nanoid } from "nanoid";
import Mary from "../../images/Mary.jpg";
import Mike from "../../images/Mike.jpg";
import flagMike from "../../images/flagMike.svg";
import flagMary from "../../images/flagMary.svg";

import Elipse from "../../images/Ellipse_1.svg";
import Triangle from "../../images/triangle.svg";
import Rhombus from "../../images/rhombus.svg";
import Square from "../../images/square.svg";

export const headerHamburgerLinks = [
  {
    name: "About",
    link_href: "about",
    id: nanoid(),
  },
  {
    name: "How it works",
    link_href: "how_it_works",
    id: nanoid(),
  },
  {
    name: "Products",
    link_href: "products",
    id: nanoid(),
  },
  {
    name: "FAQ",
    link_href: "FAQ",
    id: nanoid(),
  },
  {
    name: "Contacts",
    link_href: "contacts",
    id: nanoid(),
  },
];

export const howItWorksData = [
  {
    id: nanoid(),
    img: Square,
    imgAlt: "square",
    header: "Opportunity to try",
    text: "Before granting access to students, we provide the school counsellor with free access to all the features of the Choizy app.",
  },
  {
    id: nanoid(),
    img: Elipse,
    imgAlt: "elipse",
    header: "Easy to use",
    text: "Easy interaction is achieved through the clear design and mobile format and absence of complex tests and long surveys.",
  },
  {
    id: nanoid(),
    img: Rhombus,
    imgAlt: "rhombus",
    header: "Entertaining",
    text: "The whole process is not only focused on learning, but also entertaining. It includes different videos and elements of gamification",
  },
  {
    id: nanoid(),
    img: Triangle,
    imgAlt: "triangle",
    header: "Created for everyone",
    text: "Students use app and receive the career report, at the same time counsellors can monitor students’ progress and achievements",
  },
];

export const ReviewsData = [
  {
    id: nanoid(),
    img: Mary,
    imgFlag: flagMary,
    name: "Mary",
    place:
      "Erudito Lyceum, Kaunas, Lithuania",
    desc: "Great app! I really like that I can learn more about different professions, even about those that I have never heard of before. This application helped me expand my search and find exactly what I like.",
  },
  {
    id: nanoid(),
    img: Mike,
    imgFlag: flagMike,
    name: "Mike",
    place:
      "ONCAMPUS Amsterdam, Netherlands",
    desc: "Such a nice platform! I spent hours there watching different very useful and interesting career videos and now I understand more, which career I would like to enter.",
  },
  {
    id: nanoid(),
    img: Mary,
    imgFlag: flagMary,
    name: "Mary",
    place:
      "Erudito Lyceum, Kaunas, Lithuania",
    desc: "Great app! I really like that I can learn more about different professions, even about those that I have never heard of before. This application helped me expand.",
  },
  {
    id: nanoid(),
    img: Mike,
    imgFlag: flagMike,
    name: "Mike",
    place:
      "ONCAMPUS Amsterdam, Netherlands",
    desc: "Such a nice platform! I spent hours there watching different very useful and interesting career videos and now I understand more, which career I would like to enter.",
  },
];

export const productsData = [
  {
    headingFirstPartBr: "Subject track",
    headingSecondPart:
      "(IB DP & Cambridge A Level)",
    desc: "Created specifically for International Schools (both IB & Cambridge) and helps students to make a choice of subjects, which to study in IB DP or Cambridge A level programmes.",
    purpose:
      "For Middle school last year students.",
    time: "2-3 months",
    detailedDesc: "25   subject",
    detailedDesc_text: "more than 1000",
    price: "",
    btnText: "Request a demo",
    id: nanoid(),
    U_T: false,
  },
  // {
  //   headingFirstPartBr: "Career track",
  //   headingSecondPart:
  //     "(for any school)",
  //   desc: "We recommend to use this track during the first year of high school. Students will get familiar with 468 careers from 36 career fields and will understand, what they want to study at university",
  //   purpose:
  //     "For High school first year students",
  //   time: "3-4 months",
  //   detailedDesc: "468 careers",
  //   detailedDesc_text: "more than 3000",
  //   price: "35€",
  //   btnText: "Request a demo",
  //   id: nanoid(),
  //   U_T: false,
  // },
  {
    headingFirstPartBr:
      "University track",
    headingSecondPart:
      "(for any school)",
    desc: "Based on the chosen subjects and career preferences, students may choose the desired University and know exactly, what the requirements to enter it",
    purpose:
      "For High school last year students",
    time: "",
    detailedDesc: "",
    detailedDesc_text: "",
    price: "",
    btnText: "Coming soon",
    id: nanoid(),
    U_T: true, // University track, for btn bgc
  },
  // {
  //   headingFirstPartBr: "Choizy track",
  //   headingSecondPart: "",
  //   desc: "Get a special offer and discount from choosing the combination of several tracks. We highly recommend you this tracks, as it will give your students opportunity to make a conscious career choice",
  //   purpose: "",
  //   time: "1 year ",
  //   detailedDesc: "",
  //   detailedDesc_text: "",
  //   price: "45€",
  //   btnText: "Request a demo",
  //   id: nanoid(),
  //   U_T: false,
  // },
];

export const FAQ_Data = [
  {
    question:
      "How to start using Choizy?",
    id: nanoid(),
    answers: [
      "You need to leave your contact details and we will send you a link to download a Choizy app and the login/password details for your free access (don’t forget to leave your school email for this purpose)",

      "You try Choizy app for yourself, understand how it works and all the benefits of it.",

      "After that we either arrange a call or you reply to the email, showing your interest in subscribing your school to the service.",
      "We provide you with login/password details for all your students and they can start a one-month free trial.",

      "Closer to the end of one-month free trial you decide whether to continue using Choizy.",
      "If yes, we can sign the full agreement and your students will receive all the benefits of Choizy app with unlimited access and you can check their progress anytime.",
    ],
  },
  {
    question:
      "How to check whether Choizy is good for my students?",
    id: nanoid(),
    answers: [
      "You leave your contact details, and we provide you with free access to Choizy app, to try everything by yourself!",
    ],
  },
  {
    question:
      "Can I choose just one track?",
    id: nanoid(),
    answers: [
      "Of course. We highly recommend for all the students to start with Career track, which may help them to discover more about 400+ careers and after that they could better understand, which subject to choose for high school. However, it’s possible just to choose only Career track.",
    ],
  },
  {
    question:
      "Is Choizy only for IB or Cambridge schools?",
    id: nanoid(),
    answers: [
      "No, Choizy is possible to use in any school. Career track is fully adapted for the need of middle and high school students with at least Intermediate level of English. However, if you decide to choose Subject track after Career one, it will be more suitable for IB or Cambridge schools.",
    ],
  },
  {
    question:
      "Can we monitor the students’ progress?",
    id: nanoid(),
    answers: [
      "Of course. The school representatives will receive an access to the web-platform, where you can check the student’s activity and progress and motivate them to study harder if needed.",
    ],
  },
  {
    question:
      "How many careers are in Choizy Career track?",
    id: nanoid(),
    answers: [
      "Currently there are 468 careers from 36 career fields available in the Career track. But all the time we try to find new interesting career options and add them to our app to make the career guidance for your students even more effective.",
    ],
  },
  {
    question:
      "How many subjects are in Choizy Subject track?",
    id: nanoid(),
    answers: [
      "Before we sign an agreement, you provide us with the list of subjects, which are available at your school and we will add all the possible options in your school to your students track.",
    ],
  },

  {
    question:
      "Which schools are already using Choizy?",
    id: nanoid(),
    answers: [
      "We are currently working with international schools in Italy, Lithuania, Romania, and Ukraine. This year we are planning to expand to new countries and continents. Are you with us?",
    ],
  },
  {
    question:
      "How much does Choizy cost?",
    id: nanoid(),
    answers: [
      "The price of Choizy app depends on the number of students at your school. If you have more than 30 students, we will provide you with a discount. If you want to know more about our prices, feel free to email us: info@choizy.org",
    ],
  },
];
